.form-group {
  select {
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 5px;
    padding: 5px 8px;

    color: #4a4a4a;
    background-color: white;
  }
}
