/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import "../../node_modules/ngx-drag-to-select/ngx-drag-to-select.css";
@import "swiper.scss";
@import "form.scss";

@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/latoregular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/latolight.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/latobold.ttf");
  font-weight: 700;
}

* {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

html {
  font-family: Lato, Open Sans, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

input[type="range"] {
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
}

a {
  text-decoration: none;
  color: var(--gray-dark);

  &.active {
    color: var(--blue);
  }
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  color: transparent;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  cursor: pointer;
}

textarea {
  resize: none;
}

.container {
  margin: 0 auto;
  max-width: 1140px;
  width: 92%;

  &--big {
    max-width: 100%;
    width: 95%;
  }
}

.active > a {
  font-weight: 700;
}

html,
body {
  height: 100%;
  margin: 0;
}

.validation > small {
  color: red;
}

:root {
  --light: #f1f3fa;
  --dark: #343a40;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --green: #28a745;
  --green-dark: #218838;
  --blue: #007bff;
  --blue-dark: #0060d0;
  --blue-light: #0099ff;
  --red: #dc3545;
  --red-dark: #cc1525;
  --aside-width-full: 220px;
  --aside-width-collapsed: 65px;
  --aside-width: var(--aside-width-full);
  --aside-padding: 1.5rem;
}

.btn {
  background: #0085ff;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #0085ff;
  padding: 10px 16px;
  border-radius: 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.btn-light {
  background: #fff;
  border: 1px solid #d9d9d9;
  color: #000;

  &:hover {
    background: var(--light);
  }
}

.light-blue-btn {
  background: #e0f2ff;
  color: #09f;
  border: 1px solid #e0f2ff;

  &:hover {
    background: #cee6f7;
  }
}

.delete-btn {
  position: relative;
  margin-left: 10px;
  display: block;
  position: relative;
  margin: 0;
  padding: 8px 30px;
  background: #fff;
  line-height: 22px;
  font-size: 16px;
  color: #4a4a4a;
  border: 1px solid #d9d9d9;
  border-radius: 40px;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  padding-left: 40px;

  &:hover {
    background-color: var(--light);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 11px;
    left: 18px;
    width: 16px;
    height: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../assets/icons/close.svg");
  }

  &:hover {
    &::before {
      background-image: url("../assets/icons/close-red.svg");
    }
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 15px) !important;
  left: 15px !important;
  right: auto !important;
  bottom: auto !important;
  box-shadow: 0 3px 7px rgb(0 0 0 / 10%);
  transform: translate(0px, 0px);
  opacity: 1;
  transition: opacity 0.1s;
  box-sizing: border-box;
  padding: 5px 0;
  min-width: 140px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    box-shadow: -1px -1px 1px rgb(0 23 69 / 13%);
    height: 1rem;
    background: #fff;
    transform: rotate(45deg);
  }

  & a {
    position: relative;
    white-space: nowrap;
    padding: 8px 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    -webkit-user-drag: none;

    &:hover {
      background-color: #f2f2f2;
    }

    &.active {
      color: var(--blue);
    }
  }

  & > span {
    display: block !important;
    margin: 5px 0;
    padding: 0;
    height: 1px;
    width: 100%;
    background: #e0e0e0;
    pointer-events: none;
  }
}

// MODAL STYLES
.header-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 18px;

  & > h2 {
    margin: 0;
    line-height: 32px;
    font-size: 24px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.form-group {
  margin: 0 0 15px;

  & .field {
    & > select {
      border: none;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      margin-top: 6px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      color: #4a4a4a;
      padding: 5px 8px;
      min-width: 160px;

      & option {
        border: none;
        cursor: pointer;
      }
    }
  }

  & input,
  textarea {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4a4a4a;
    background: #fff;
  }

  &-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > label {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  &_small {
    & input {
      width: 160px;
    }
  }
}
.controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 25px 0 0;
}

.dts-select-box {
  background: rgb(251 249 249 / 35%);
  color: #ece8e8;
  border-width: 1px;
}

.authorization {
  &__content {
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    padding: 48px 48px;
    background: rgba(18, 18, 18, 0.06);
    border-radius: 8px;
    max-width: 450px;
    width: 100%;

    @media screen and (max-width: 480px) {
      padding: 48px 24px;
    }
  }
}

.form {
  &__title {
    font-weight: 800;
    padding-bottom: 25px;
    font-size: 22px;
  }

  &__input-wrapper {
    position: relative;
    width: 100%;
    transition: 0.3s ease-in-out;

    &-alert {
      color: red;
    }

    &-blocked::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px;
      background-color: black;
      opacity: 0.1;
      cursor: not-allowed;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .ng-invalid {
      border: 1px solid red;
    }

    .ng-pristine {
      border: 1px solid transparent;
    }
  }

  &__link {
    cursor: pointer;
    display: block;
    text-decoration: underline;
    margin-top: -10px;
    padding: 0px 0px 10px 5px;

    &:hover {
      opacity: 0.7;
    }
  }

  &__input {
    padding: 22px 16px 6px;
    width: 100%;
    background: rgba(18, 18, 18, 0.06);
    border-radius: 8px;
    border: none;
    border: 1px solid transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: #121212;

    &:focus {
      border-color: var(--blue);
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: rgba(18, 18, 18, 0.25);
    pointer-events: none;
    transition: 0.1s;

    &-centered {
      top: calc(50% - 8px);
    }
  }

  &__input:focus + &__text {
    transform: unset;
    top: 6px;
    left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 117%;
    color: rgba(18, 18, 18, 0.55);
  }

  .active + &__text {
    transform: unset;
    top: 6px;
    left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 117%;
    color: rgba(18, 18, 18, 0.55);
  }

  &__eye-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;

    &:hover {
      opacity: 0.5;
    }
  }

  &__error {
    color: red;
    font-size: smaller;
    padding-left: 5px;
    margin-top: -20px;
    margin-bottom: 5px;
  }
}

.tags {
  font-family: Lato, Open Sans, Helvetica, sans-serif;

  & ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    line-height: 1em;

    & li:not(:last-of-type) {
      background-color: #09f;
      padding: 3px 6px;
      border-radius: 6px;
      margin: 2px;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 28px;
      margin-right: 5px;
      margin-left: 0;
    }
  }

  &__input {
    flex: 1 0 auto;
    min-width: 100px;
    border: none;
    padding: 3px 0;
    margin: 0px;
    background-color: transparent;

    & > input {
      padding: 0 5px;
      outline: none;
      background-color: #fff;
      font-style: italic;
      border: none;
      font-size: 16px;
      line-height: 22px;
      height: 28px;
    }
  }
}

.tags__content {
  display: flex;
  align-items: center;

  & span {
    color: #fff;
    font-size: 0.85rem;
  }
}

.tags__actions {
  margin-left: 4px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-size: 1.15em;

  & > button {
    cursor: pointer;
    background: none;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}

.outlined {
  & > input {
    width: auto;
    padding: 4px 6px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    outline: 0;
    box-shadow: none;
    font-style: normal;
    width: 150px;

    &:focus {
      border-color: var(--blue);
    }
  }
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}

ngx-skeleton-loader + ngx-skeleton-loader {
  > :first-child {
    margin-top: 1em;
  }
}

// Jivo chat widget selectors
// Desktop widget
jdiv jdiv.notranslate,
// Mobile widget
jdiv.__jivoMobileButton {
  // Prevent laying over zoomed photo and mobile navigation panel
  z-index: 15 !important;
}

.noscroll {
  overflow: hidden;
}
