div.mat-chip-list-wrapper {
  margin: 0;
  gap: 5px;
}

.cdk-overlay-pane {
  // Stretch Dialog's header and footer to draw their borders across whole dialog's widht
  $mat-dialog-content-padding: 24px;
  $border-color: rgba(217, 217, 217, 1);
  mat-dialog-title, .mat-dialog-title,
  mat-dialog-actions, .mat-dialog-actions {
    margin-left: -$mat-dialog-content-padding;
    margin-right: -$mat-dialog-content-padding;
    padding-left: $mat-dialog-content-padding;
    padding-right: $mat-dialog-content-padding;
  }
  mat-dialog-title, .mat-dialog-title {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
  }
  mat-dialog-actions, .mat-dialog-actions {
    border-top: 1px solid $border-color;
  }
}

.mat-tooltip {
  width: 300px;
  padding: 12px !important;
  color: black !important;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px !important;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  max-width: none !important;
  margin: 0 !important;
}
